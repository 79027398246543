import { Button, Card, Divider, Grid, Group, Text } from "@mantine/core";
import { openConfirmModal, openModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import {
  getGetApiOrganizationGetOrganizationsKey,
  postApiOrganizationDeleteOrganization,
  useGetApiOrganizationGetOrganizations,
} from "../../api/endpoints";
import RegisterOrganization from "../../components/forms/RegisterOrganization";
import Loading from "../../components/Loading";
import SelectOrganization from "../../components/SelectOrganization";
import {useConfig} from "../../hooks/useConfig";

function OrganizationsList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const config  = useConfig();
  const {
    data: orgs,
    error: stepsError,
    isLoading,
  } = useGetApiOrganizationGetOrganizations();

  if (isLoading) {
    return <Loading></Loading>;
  }
  if (!Array.isArray(orgs) || stepsError) {
    mutate(getGetApiOrganizationGetOrganizationsKey());
    return <Loading></Loading>;
  }

  const openRegisterOrgModal = () =>
    openModal({
      title: t("pages.organizations.register-organization"),
      children: <RegisterOrganization></RegisterOrganization>,
    });

  const openConfirmationModal = (orgId: string) =>
    openConfirmModal({
      title: t("pages.organizations.delete-organization"),
      children: (
        <Text size="sm">
          {" "}
          {t("pages.organizations.confirm-delete-organization")}
        </Text>
      ),
      labels: {
        confirm: t("pages.organizations.confirm"),
        cancel: t("pages.organizations.cancel"),
      },
      onConfirm: () => confirmModalAction(orgId),
    });

  const confirmModalAction = async (orgId: string) => {
    await postApiOrganizationDeleteOrganization({ orgId: orgId }).then(
      (res) => {
        if (res) {
          alert(
            t("pages.organizations.message-organization-deleted-successfully")
          );
        } else alert(t("pages.organizations.message-error-deletion"));
      }
    );
  };
  return (
    <>
      <SelectOrganization />
      <Divider></Divider>

      <Grid columns={12}>
        <Grid.Col span={3}>
          <Text size="xl" weight={700} my="xl">
            {t("pages.organizations.list-organizations")}
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
            {config.organizationSelection && <Button
                variant="filled"
                color="blue"
                mt="xl"
                radius="sm"
                style={{float: "right"}}
                onClick={() => openRegisterOrgModal()}
            >
                {t("pages.organizations.add-organization")}
            </Button>}
        </Grid.Col>
      </Grid>

      {orgs?.map((org) => (
        <Card key={org.id} shadow="sm" p="md" radius="md" mb="md" withBorder>
          <Group position="apart" mb="xs">
            <Text size="lg">
              {org.name} - {org.reg_number}
            </Text>
          </Group>

          <Divider mt="lg"></Divider>
          <Button
            variant="light"
            color="green"
            mt="sm"
            radius="sm"
            style={{ float: "right" }}
            onClick={() => navigate(`/org/edit/${org.id}`)}
          >
            {t("pages.organizations.update")}
          </Button>
          <Button
            variant="light"
            color="red"
            mt="sm"
            radius="sm"
            style={{ float: "right" }}
            onClick={() => openConfirmationModal(org?.id!)}
          >
            {t("pages.organizations.delete")}
          </Button>
        </Card>
      ))}
    </>
  );
}

export default OrganizationsList;

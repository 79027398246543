import {
  Checkbox,
  Drawer,
  Flex,
  ScrollArea,
  Space,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {useEffect, useState, useCallback, useContext} from "react";
import {
  postApiIndicatorGetIndicatorType,
  postApiOutcomeGetOutcomeType,
} from "../../api/endpoints";
import { IndicatorTypeDTO, OutcomeTypeDTO, SdgDTO } from "../../api/model";
import SDGImage from "./SDGImage";
import useMiniDyiStore from "./miniDYIStore";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "@tabler/icons";
import { useDisclosure } from "@mantine/hooks";
import {ImpactFrameworkIdContext} from "./MiniDYI";

function SelectOutcome() {
  const impactFrameworkId = useContext(ImpactFrameworkIdContext);
  const sdg = useMiniDyiStore((state) => state.sdg);
  const { t } = useTranslation();
  const toggleOutcome = useMiniDyiStore((state) => state.toggleOutcome);
  const selectedOutcomes = useMiniDyiStore((state) => state.selectedOutcomes);
  const [outcomeTypeList, setOutcomeTypeList] = useState<OutcomeTypeDTO[][]>();
  const [indicatorTypeList, setIndicatorTypeList] =
    useState<IndicatorTypeDTO[][]>();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedOutcomeDetails, setSelectedOutcomeDetails] = useState<
    OutcomeTypeDTO | undefined
  >();

  const getIndicatorTypes = useCallback(async (outcomeIds: string[]) => {
    return await postApiIndicatorGetIndicatorType(outcomeIds);
  }, []);

  useEffect(() => {
    if (Array.isArray(outcomeTypeList)) {
      getIndicatorTypes(
        outcomeTypeList.reduce((a, b) => a.concat(b), []).map((o) => o.id!)
      ).then((indicatorTypes) => {
        setIndicatorTypeList(indicatorTypes);
      });
    }
  }, [
    postApiIndicatorGetIndicatorType,
    selectedOutcomes,
    selectedOutcomeDetails,
  ]);

  useEffect(() => {
    const getOutcomeTypes = async () => await postApiOutcomeGetOutcomeType(sdg,{ifSetId:impactFrameworkId});

    getOutcomeTypes().then((outcomeTypes) => {
      console.log(outcomeTypes);
      setOutcomeTypeList(outcomeTypes);
    });
  }, [postApiOutcomeGetOutcomeType, sdg,impactFrameworkId]);

  return (
    <>
      <Title order={2}>{t("components.mini-dyi.outcomes")}</Title>
      <Space h={"md"} />
      <Text>{t("components.mini-dyi.outcome-description")}</Text>
      <Space h="xl" />
      <>
        {outcomeTypeList &&
          outcomeTypeList?.map((group) => {
            return group
              .sort((a, b) =>
                a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0
              )
              .map((out) => {
                return (
                  <Flex
                    key={out.id}
                    justify={"space-evenly"}
                    py={15}
                    align={"center"}
                    w={"100%"}
                    style={{
                      borderBottom: "1px solid #eee",
                      minHeight: "3rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      toggleOutcome({
                        outcomeId: out.id,
                        sdg: out.sdg,
                        outcomeName: out.name,
                        indicators: [],
                        stakeholderId: "",
                        stakeholderName: "",
                      });
                    }}
                  >
                    <Checkbox
                      mx={5}
                      checked={
                        !!selectedOutcomes.find((so) => so.outcomeId === out.id)
                      }
                    />
                    <Text size="md" w="50ch" align="left">
                      {out.name}
                    </Text>
                    <IconInfoCircle
                      size={16}
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        marginLeft: ".2rem",
                        marginBottom: ".2rem",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedOutcomeDetails(out);
                        open();
                      }}
                    ></IconInfoCircle>
                    <SDGImage id={out.sdg !== undefined ? out.sdg : 0} />
                  </Flex>
                );
              });
          })}
        <Drawer
          opened={opened}
          onClose={close}
          position={"right"}
          overlayProps={{ opacity: 0.4, blur: 4 }}
        >
          {selectedOutcomeDetails && (
            <>
              <Text size="md" py={6}>
                {t("components.mini-dyi.indicators-in")} "
                {selectedOutcomeDetails.name}"
              </Text>

              <ScrollArea h={250}>
                <Table striped>
                  <tbody>
                    {indicatorTypeList &&
                      indicatorTypeList
                        .filter(
                          (l) =>
                            !!l.filter(
                              (i) =>
                                i.outcomeTypeId === selectedOutcomeDetails.id
                            ).length
                        )[0]
                        ?.map((i) => {
                          return (
                            <tr>
                              <Text size={"sm"} py={3}>
                                {i.name}
                              </Text>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </ScrollArea>
            </>
          )}
        </Drawer>
      </>
    </>
  );
}

export default SelectOutcome;

import {
  Checkbox,
  Drawer,
  Flex,
  Image,
  SimpleGrid,
  Space,
  Table,
  Text,
  Title,
} from "@mantine/core";
import useMiniDyiStore from "./miniDYIStore";
import {
  postApiOutcomeGetOutcomeType,
  useGetApiImpactFrameworkGetSdg,
} from "../../api/endpoints";
import { useTranslation } from "react-i18next";
import {useCallback, useContext, useEffect, useState} from "react";
import { OutcomeTypeDTO, SdgDTO } from "../../api/model";
import { IconInfoCircle } from "@tabler/icons";
import { useDisclosure } from "@mantine/hooks";
import {ImpactFrameworkIdContext} from "./MiniDYI";

function SelectSDG() {
    const impactFrameworkId = useContext(ImpactFrameworkIdContext);
  const toggleSdg = useMiniDyiStore((state) => state.toggleSdg);
  const { t } = useTranslation();
  const [selectedSDG, setSelectedSDG] = useState<SdgDTO | undefined>();
  const [opened, { open, close }] = useDisclosure(false);
  const { data: sdgs } = useGetApiImpactFrameworkGetSdg();
  const [outcomeTypesList, setOutcomeTypesList] =
    useState<OutcomeTypeDTO[][]>();
  const selectedSdgs = useMiniDyiStore((state) => state.sdg);
  const getOutcomeTypes = useCallback(async (sdgCodes: number[]) => {
    return await postApiOutcomeGetOutcomeType(sdgCodes,{ifSetId:impactFrameworkId});
  }, [impactFrameworkId]);
  useEffect(() => {
    if (Array.isArray(sdgs)) {
      const sdgcodes = sdgs!.map((sdg) => sdg.sdgCode);
      getOutcomeTypes(sdgcodes).then((outcomeTypes) => {
        setOutcomeTypesList(outcomeTypes);
      });
    }
  }, [postApiOutcomeGetOutcomeType, sdgs,impactFrameworkId]);
  return (
    <>
      <Title order={2}>{t("components.mini-dyi.sdg")}</Title>
      <Space h="md" />
      <Text>{t("components.mini-dyi.sdg-description")}</Text>
      <Space h="xl" />
      <SimpleGrid cols={2}>
        {sdgs
          ?.sort((a, b) => a.sdgCode - b.sdgCode)
          .map((sdg) => {
            return (
              <div key={sdg.sdgCode} onClick={() => toggleSdg(sdg.sdgCode)}>
                <Flex align={"center"} gap={6}>
                  <Checkbox
                    checked={
                      !!selectedSdgs.filter((sdgId) => sdgId == sdg.sdgCode)
                        .length
                    }
                  />

                  <Space h="sm" />
                  <Flex justify={"flex-start"} align={"center"}>
                    <Image width={60} height={60} src={sdg.url} />
                    <Flex
                      justify={"flex-start"}
                      direction={"column"}
                      align={"flex-start"}
                      w={"100%"}
                    >
                      <Text size={"sm"} ml={12} weight={"bold"}>
                        {sdg.name}
                        <IconInfoCircle
                          size={16}
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                            marginLeft: ".2rem",
                            marginBottom: ".2rem",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedSDG(JSON.parse(JSON.stringify(sdg)));
                            open();
                          }}
                        ></IconInfoCircle>
                      </Text>
                      <Text size={"xs"} ml={12}>
                        {sdg.keywords}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            );
          })}
      </SimpleGrid>
      <Drawer
        opened={opened}
        onClose={close}
        position={"right"}
        overlayProps={{ opacity: 0.4, blur: 4 }}
      >
        {selectedSDG && (
          <>
            <Flex justify={"flex-start"} gap={12}>
              <Image width={100} height={100} src={selectedSDG.url} />
              <Flex justify={"flex-start"} direction={"column"}>
                <Text size="lg" weight={"bold"}>
                  {selectedSDG.name}
                </Text>
                <Text size="md" pt={6} pb={12}>
                  {selectedSDG.description}
                </Text>
              </Flex>
            </Flex>
            <hr></hr>
            <Text
              size="md"
              pt={12}
              pb={6}
              transform={"uppercase"}
              weight={"bold"}
            >
              {t("components.mini-dyi.outcomes-available-sdg")}
            </Text>

            <Table striped>
              <tbody>
                {outcomeTypesList &&
                  !!outcomeTypesList
                    .filter(
                      (l) =>
                        !!l.filter((s) => s.sdg === selectedSDG.sdgCode).length
                    ).length &&
                    outcomeTypesList
                    .filter(
                        (l) =>
                            !!l.filter((s) => s.sdg === selectedSDG.sdgCode).length
                    )[0]
                    .map((o) => {
                      return (
                        <tr>
                          <Text size={"md"} py={6}>
                            {o.name}
                          </Text>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </>
        )}
      </Drawer>
    </>
  );
}

export default SelectSDG;

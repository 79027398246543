import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, useRefreshToken } from "../auth/AuthContext";
import useSelectedOrganizationId from "../hooks/useSelectedOrganizationId";

const LogoutCallback = () => {
  const { setAccessToken } = useAuth();
  const [_, setRefreshToken] = useRefreshToken();
  const [selectedOrgId, setSelectedOrgId] = useSelectedOrganizationId();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("LOGGING OUT");
    setAccessToken(null);
    setRefreshToken(null, 30);
    setSelectedOrgId(null);
    navigate("/", { replace: true });
  });

  return <></>;
};
export default LogoutCallback;
